import React from 'react';
import './Nav.css';


const Nav = ( props ) => {

    return (
        <div className="formNav__section">
            <div className="formNav__title">
                <a href={`#${props.scroll}`}><span>{props.title}</span></a>
            </div>
        </div>
    )
}

export default Nav;



