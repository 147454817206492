//**TODO handle blank theme - checkbox hide themes
//**TODO add autotype for OEM
import React, {useCallback} from 'react';
import axios from 'axios';

import './NewSiteForm.css';
import foxlogo from '../foxlogo.png'

import FormElement from '../components/FormElement/FormElement';
import FormSection from '../components/FormSection/FormSection';
import Nav from '../components/Nav/Nav';
import getInitFormData from '../config/initialFormData';
import initialAuthFormData from "../config/initialAuthFormData";
import updateForm from '../config/updateForm';
import inventoryProviderFields from '../config/inventoryProviderFields';
import homepageNewVehicleModelThemes from '../config/homepageNewVehicleModelThemes';
import Swal from 'sweetalert2';
const debounce = require( 'lodash.debounce' );

let initialFormData = getInitFormData();

const NewSiteForm = () => {

    //set up config for form from initialFormData
    //create array of form elements from config
    const formSectionsArray = [];

    for (let key in initialFormData) {

        formSectionsArray.push({
            id: key,
            title: initialFormData[key].title,
            items: initialFormData[key].items,
            desc: initialFormData[key].desc
        });
    }

    //use react hook and create state
    const [formData, updateFormData] = React.useState(initialFormData);
    const [authFormData, updateAuthFormData] = React.useState( initialAuthFormData );
    const [formDataIndexMap] = React.useState({});
    const [searchMap] = React.useState({});
    const [showIds, setShowIds] = React.useState([]);
    const [isLoading, updateIsLoading] = React.useState(true);
    const [authenticated, updateAuthenticated] = React.useState( false );
    const [formUpdater, updateFormUpdater] = React.useState( () => {});

    const processSearch = async ( e ) => {
        const searchValue = e.target.value;
        let ids = [];
        if ( searchValue ){
            for ( const [ hash, id ] of Object.entries( searchMap ) ){
                let rgx = new RegExp( `(${searchValue})`, 'gi' )
                const matches = hash.match( rgx );
                if ( matches && Array.isArray( matches ) && matches.length ){
                    ids.push( id );
                }
            }
        }
        setShowIds( ids );
        updateIsLoading( false );
    }

    const keyDownCallback = useCallback( debounce( processSearch, 500 ), [] );

    const onKeyDownSearchInput = ( e ) => {
        updateIsLoading( true );
        keyDownCallback( e );
    }

    //handle input change and update state
    const handleInputChange = async ( event, sectionKey, formElement, index, formType = 'website' ) => {

        let updatedForm;
        switch ( formType ){
            case 'auth':
                updatedForm = {...authFormData};
                break;
            default:
                updatedForm = {...formData};
                break;
        }

        const value = event.target.value;

        await formUpdater.update( event, sectionKey, index, updatedForm );


        if ( event.target.name === 'class' && value.toLowerCase() !== 'none' ) {
            updateInventoryProviderInputs( value, updatedForm );
        }

        if (event.target.name === 'frontpage_new_vehicle_module') {

            updateNewVehicleTheme( value, updatedForm );
        }


        switch ( formType ){
            case 'auth':
                updateAuthFormData( { ...updatedForm } );
                break;
            default:
                updateFormData( { ...updatedForm } );
                break;
        }

    }

    const updateNewVehicleTheme = (theme, formState) => {
        formState["homepageNewVehicleModel"]["items"].length = 2;

        if (homepageNewVehicleModelThemes[theme]) {

            homepageNewVehicleModelThemes[theme].forEach(function(obj, i) {
                formState["homepageNewVehicleModel"]["items"].push(obj);
            });
        }
    }

    const updateInventoryProviderInputs = (provider, formState) => {

        //keep backend items to original state items
        formState["backend"]["items"].length = 4;

        //then add provider inputs
        if (inventoryProviderFields[provider]) {
            inventoryProviderFields[provider].forEach(function(obj, i) {
                formState["backend"]["items"].push(obj);
            });
        }

    }

    const clearFormFields = ( fields = [] ) => {
        if ( Array.isArray( fields ) ){
            let fieldsLength = fields.length
            if ( fieldsLength === 0 ){
                initialFormData = getInitFormData();
                updateFormData( initialFormData );
            }
            else{
                for ( let i = 0; i < fieldsLength; i++ ){
                    let name = fields[i];
                    let fieldData = formDataIndexMap[name];
                    if ( fieldData ){
                        let updatedForm = { ...formData };
                        let ev = {
                            target: {
                                name: name,
                                value: '',
                            }
                        };
                        formUpdater.update( ev, fieldData.sectionKey, fieldData.index, updatedForm );
                        updateFormData( { ...updatedForm } );
                    }
                }
            }
        }
    };

    //handle submit
    const handleSubmit = (event) => {

        event.preventDefault()
        const data = {};

        Swal.showLoading();

        //create simple Object to submit data
        for (let key in initialFormData) {
            let configArr = initialFormData[key].items;

            for (let i=0; i< configArr.length; i++) {
                const configData = {};

                configData.value = configArr[i].config.value;
                configData.source = configArr[i].config.source;

                data[configArr[i].id] = configData;
            }
        }

        console.log('data submitted', data);

        const url ='https://api.foxdealer.com/fdi/sitebuilder/create/site';
        // const url = 'http://localhost:3000/fdi/sitebuilder/create/site';

        axios({
            method: 'post',
            url,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data,
        }).then( ( r ) => {
            console.log({
                submitFormDataResponse: r,
            });
            r = r.data;
            if ( r && r.data && r.data.success ){
                let submissionHTML = `
                <div>
                    <p id="submit-feedback-msg">${ r.data.msg }</p>
                    <div>
                        <button type="button" id="submit-feedback-reset-form">Reset Form Data</button>
                        <button type="button" id="submit-feedback-keep-form">Keep Current Form Data</button>
                    </div>
                </div>
                `;
                Swal.fire({
                    title: 'Success',
                    html: submissionHTML,
                    icon: 'success',
                    showConfirmButton: false,
                    didOpen: () => {
                        let resetForm = document.getElementById( 'submit-feedback-reset-form' );
                        resetForm.addEventListener( 'click', function (){
                            clearFormFields();
                            Swal.close();
                        });
                        let keepForm = document.getElementById( 'submit-feedback-keep-form' );
                        keepForm.addEventListener( 'click', function (){
                            let fields = [
                                'siteName',
                                'siteURL'
                            ];
                            clearFormFields( fields );
                            Swal.close();
                        });
                    },
                });
            }
        }).catch( ( err ) => {
            console.log({
                submitFormDataError: err,
            })
            if ( !err.response ){
                Swal.fire({
                    title: `${ err.status } Error`,
                    text: err.msg || err.message || 'An unknown error has occurred.',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                });
            }
            else{
                err = err.response.data;
                if ( err && !err.success ){
                    Swal.fire({
                        title: `${ err.status } Error`,
                        text: err.msg || 'An unknown error has occurred.',
                        icon: 'error',
                        confirmButtonText: 'Okay',
                    });
                }
            }
        });

    }

    const handleAuthSubmit = async ( e ) => {
        e.preventDefault()
        const data = {};

        Object.entries( initialAuthFormData ).map( ( section ) => {
            section[1].items.forEach( ( item ) => {
               data[item.id] = item.config.value;
            });
        });

        await attemptLogin( data );
    };

    let newSiteForm = (
        <div className={'loaderContainer'}>
            <div className="loader">
                <div></div>
                <div></div>
            </div>
        </div>
    );

    if ( !isLoading ){
        if ( !authenticated ){
            newSiteForm = (
                <form onSubmit={ handleAuthSubmit }>
                    {Object.entries( initialAuthFormData ).map( ( section ) => (
                        section[1].items.map((formElement, index) => (
                            <div key={`${formElement.id}_container`}>
                                <FormElement
                                    key = {`${formElement.id}_input`}
                                    elementType={formElement.config.elementType}
                                    elementConfig = {formElement.config.elementConfig}
                                    value = {formElement.config.value}
                                    label= {formElement.config.label}
                                    name= {formElement.id}
                                    id= {formElement.id}
                                    index = {index}
                                    changed = {(event) => handleInputChange( event, section[0], formElement, index, 'auth' )}
                                />
                                {
                                    formElement.config.error && formElement.config.error.value &&
                                    <div key={`${formElement.id}_error`} className={'inputErrorMessage'}>
                                        <span dangerouslySetInnerHTML={{__html: formElement.config.error.msg}}>

                                        </span>
                                    </div>
                                }
                            </div>
                        ))
                    ))}
                    <button type="submit">Submit</button>
                </form>
            );
        }
        else{
            newSiteForm = (
                <form onSubmit={handleSubmit}>
                    {formSectionsArray.map((section, index) => {
                        const items = section.items.filter( item => {
                            if ( showIds.length ){
                                return showIds.includes( item.id );
                            }
                            return true;
                        });
                        if ( !items.length ){
                            return '';
                        }
                        return (
                            <FormSection title={section.title} description={section.desc} id={section.id} key={section.id}>
                                {
                                    items.map((formElement, index) => {
                                        return (
                                            <div key={`${formElement.id}_container`} className={`inputItemWrap ${formElement.id}`}>
                                                <FormElement
                                                    key = {`${formElement.id}_input`}
                                                    elementType={formElement.config.elementType}
                                                    elementConfig = {formElement.config.elementConfig}
                                                    value = {formElement.config.value}
                                                    label= {formElement.config.label}
                                                    name= {formElement.id}
                                                    id= {formElement.id}
                                                    index = {index}
                                                    changed = {(event) => handleInputChange(event, section.id, formElement, index)}
                                                />
                                                {
                                                    formElement.config.error && formElement.config.error.value &&
                                                    <div key={`${formElement.id}_error`} className={'inputErrorMessage'} >
                                                        <span>{formElement.config.error.msg}</span>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </FormSection>
                        )
                    })}
                    <button type="submit">Submit</button>
                </form>
            );
        }
    }

    let formNav = (
        <nav className="NewSiteForm__nav">
            <img src={foxlogo} alt="Fox Dealer" className="header__logo" />

            <div className="tagline">
            Fill Out Form to Create a New Site!
            </div>
            <div className="searchInputContainer">
                <label htmlFor="search">Search: </label>
                <input id="search" onKeyDown={ e => onKeyDownSearchInput( e )} type="text" />
            </div>
            {formSectionsArray.map((section, index) => (
                <Nav title={section.title} scroll={section.id} key={`${section.id}_nav`} />
            ))}
        </nav>
    );

    let loginScreenHeader = (
        <div className = "loginScreenHeader">
            <img src={foxlogo} alt="Fox Dealer" className="header__logo" />
            <h1>Website Automation Tool</h1>
        </div>
    )

    const fetchExistingDomains = async () => {
        const url = 'https://foxdealersites.com/wp-json/api/v1/domains';
        await axios({
            method: 'get',
            url,
            crossDomain: true,
        }).then( ( r ) => {
            console.log({
                fetchExistingDomainsResponse: r,
            });
            r = r.data;
            if (
                r &&
                r.hasOwnProperty( 'success') &&
                r.success === true &&
                r.hasOwnProperty( 'status' ) &&
                r.status === 200 &&
                r.hasOwnProperty( 'domains' )
            ) {
                updateFormUpdater( updateForm( formDataIndexMap, r.domains ) );
                updateIsLoading( false );
                let updatedForm = { ...formData };
                const opts = r.domains.map( ( domain ) => {
                    return {
                        value: domain,
                        displayValue: domain,
                    }
                });
                opts.sort( ( a, b ) => {
                    if ( a.value < b.value ) return -1;
                    if ( a.value > b.value ) return 1;
                    return 0;
                });
                updatedForm.jellybeans.items[formDataIndexMap.jellybeans_import_site.index].config.elementConfig.options = [
                    ...updatedForm.jellybeans.items[formDataIndexMap.jellybeans_import_site.index].config.elementConfig.options,
                    ...opts
                ];
            }
        }).catch( ( err ) => {
            console.log({
                fetchExistingDomainsError: err,
            });
        });
    };

    React.useEffect( () => {
        fetchExistingDomains();
    }, [] );

    const attemptLogin = async ( data ) => {
        updateIsLoading( true );
        data = {
            ...data,
            ...{
                action: 'auth',
                api_key: 'Hwkjbc7g3mk7ghi2ubgs',
            }
        };
        const url = 'https://www.foxdealersites.com/wp-json/api/v1/auth';
        await axios({
            method: 'post',
            url,
            data,
            crossDomain: true,
        }).then( ( r ) => {
            updateIsLoading( false );
            if ( r.data && r.data.success === true && r.data.status === 200 && r.data.auth ){
                let userData = r.data.auth.data || null;
                if ( userData && !userData.hasOwnProperty( 'errors' ) && userData.ID && userData.hasOwnProperty( 'isStaff' ) && userData.isStaff ){
                    updateAuthenticated( true );
                }
                else if ( r.data.auth.errors ){
                    const authError = {
                        value: true,
                        msg: 'Incorrect username or password.',
                    };
                    let firstError = Object.values( r.data.auth.errors )[0] || null;
                    if ( firstError ){
                        const msg = firstError[0] || '';
                        if ( msg ){
                            authError.msg = msg;
                        }
                        formUpdater.setForm( authFormData, 'login', 0, null, authError );
                        updateAuthFormData({ ...authFormData } );
                    }
                }
            }
        }).catch( ( err ) => {
            updateIsLoading( false );
            console.log({
                authErrorResponse: err,
            });
        });
    };

    React.useEffect( () => {
        formSectionsArray.forEach( ( section ) => {
            section.items.forEach( ( formElement, index ) => {
                let searchMapHash = [];
                searchMapHash.push( section.title );
                searchMapHash.push( formElement.config.label );
                searchMapHash = [
                    ...searchMapHash,
                    ...formElement.config.searchTags || [],
                ];
                formDataIndexMap[formElement.id] = {
                    sectionKey: section.id,
                    index,
                };
                searchMap[
                    searchMapHash
                    .join( '_' )
                    .replace( ' ', '_' )
                    .replace( /[^A-Za-z0-9_]/g, '' )
                    .trim()
                ] = formElement.id;
            });
        });
    }, [searchMap, formDataIndexMap] );

    return (
        <div className={`NewSiteForm__main ${!authenticated ? 'login-screen' : ' logged-in'}`}>

            {loginScreenHeader}

            {formNav}

            {newSiteForm}

        </div>
    )
}

export default NewSiteForm;
