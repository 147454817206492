import React from 'react';
import './FormElement.css';
import { FaInfoCircle } from 'react-icons/fa';

const formElement = (props) => {
    let element = null;

    switch (props.elementType) {
        case ('input'):
            element = <input
                {...props.elementConfig}
                name={props.name}
                id={props.id}
                data-index={props.index}
                checked={props.elementConfig.type === 'checkbox' && props.value}
                value={props.value}
                onChange={props.changed}
            />;

            break;
        case ('textarea'):
            element = <textarea
                {...props.elementConfig}
                name={props.name}
                id={props.id}
                data-index={props.index}
                value={props.value}
                onChange={props.changed}
            />;

            break;
        case ('select'):
            let key = `${props.id}_select_key`;
            element = (
                <select
                    value={props.value}
                    name={props.name}
                    id={props.id}
                    key={ key }
                    data-index={props.index}
                    onChange={props.changed}
                    >
                    {
                        props.elementConfig.options.map( ( option, i ) => {
                            return (
                                <option
                                    key={ `${key}_${i}` }
                                    value={option.value}
                                >
                                    {option.displayValue}
                                </option>
                            )
                        })
                    }

                </select>
            );

            break;
        case ('image'):
            element = (
                <img
                    key = {`${props.id}_img`}
                    alt = {props.id}
                    name= {props.id}
                    id= {props.id}
                    src={props.value}
                    width={props.elementConfig.width}
                />
            );
            break;
        default:
            element = <input
               {...props.elementConfig}
               name={props.name}
               id={props.id}
               data-index={props.index}
               value={props.value}
               onChange={props.changed}
            />;
    }

    let inputClass = `inputItem inputItem__${props.elementConfig.type}`
    let tooltipSettings = null;
    if ( props.elementConfig && props.elementConfig.tooltip && props.elementConfig.tooltip.tooltipHTML ){
        tooltipSettings = {
            tooltipText: props.elementConfig.tooltip.tooltipHTML,
            style: {
                general: props.elementConfig.tooltip.tooltipCSS || {},
            }
        };
    }
    return (
        <div className={inputClass}>
            <label htmlFor={props.name}>
                {props.label}&nbsp;
                {
                    tooltipSettings &&
                    <span className={ 'infoIconContainer' }>
                        <FaInfoCircle />
                        <fdi-tooltip __settings={ JSON.stringify( tooltipSettings ) } />
                    </span>
                }
            </label>
            {element}
        </div>
    )
}

export default formElement;
