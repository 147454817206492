const inventoryProviderFields = {
        FDIHomenetSource: [
            {
                "id": "rooftop",
                "config": {
                    elementType: 'input',
                    elementConfig: {
                        type: "text"
                    },
                    value: '',
                    source: 'inventory_source',
                    label: 'Rooftop ID'
                }
            },
            {
                "id": "filter",
                "config": {
                    elementType: 'input',
                    elementConfig: {
                        type: "text"
                    },
                    value: '',
                    source: 'inventory_source',
                    label: 'Inventory Filter String'
                }
            }
        ],
        FDIGigiSource: [
            {
                "id": "guid",
                "config": {
                    elementType: 'input',
                    elementConfig: {
                        type: "text"
                    },
                    value: '',
                    source: 'inventory_source',
                    label: 'GUID'
                }
            }
        ],
        FDIICCSource: [
            {
                "id": "api_key",
                "config": {
                    elementType: 'input',
                    elementConfig: {
                        type: "text"
                    },
                    value: '',
                    source: 'inventory_source',
                    label: 'API Key'
                }
            }
        ],
        FDIICCWithIncentivesSource: [{
            "id": "export_id",
            "config": {
                elementType: 'input',
                elementConfig: {
                    type: "text"
                },
                value: '',
                source: 'inventory_source',
                label: 'Export ID'
            }
        }],
        FDIICCNextSource: [{
            "id": "export_id",
            "config": {
                elementType: 'input',
                elementConfig: {
                    type: "text"
                },
                value: '',
                source: 'inventory_source',
                label: 'Export ID'
            }
        }],
        FDIR22Source: [{
            "id": "api_key",
            "config": {
                elementType: 'input',
                elementConfig: {
                    type: "text"
                },
                value: '',
                source: 'inventory_source',
                label: 'API Key'
            }
        }]
}

export default inventoryProviderFields;
