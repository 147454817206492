const initialAuthFormData = {
    login: {
        title: 'Login',
        items: [
            {
                id: "username",
                config: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Username'
                    },
                    value: '',
                    label: 'Username: '
                }
            },
            {
                id: "password",
                config: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        placeholder: 'Password',
                        disabled: false
                    },
                    value: '',
                    label: 'Password:'
                }
            },
        ]
    },
}

export default initialAuthFormData;