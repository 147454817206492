const homepageNewVehicleModelThemes = {
    Parallax : [
        {
            "id": "frontpage_new_vehicle_parallax",
            "config": {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {
                            'value': 'default',
                            'displayValue': 'default'
                        },
                        {
                            'value': 'default flat',
                            'displayValue': 'default flat'
                        },
                        {
                            'value': 'flat stacked',
                            'displayValue': 'flat stacked'
                        },
                        {
                            'value': 'modern',
                            'displayValue': 'modern'
                        },
                        {
                            'value': 'edge',
                            'displayValue': 'edge'
                        }
                    ]
                },
                value: 'default flat',
                source: 'customizer',
                label: 'Parallax Theme'
            }
        },
    ],
    "Model Slider": [
        {
            "id": "frontpage_new_vehicle_model_slider",
            "config": {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {
                            'value': 'Bold',
                            'displayValue': 'Bold'
                        },
                        {
                            'value': 'Default',
                            'displayValue': 'Default'
                        }
                    ]
                },
                value: 'Default',
                source: 'customizer',
                label: 'Model Slider Theme'
            }
        },
        {
            "id": "frontpage_new_vehicle_model_slider_position",
            "config": {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {
                            'value': 'Center',
                            'displayValue': 'Center'
                        },
                        {
                            'value': 'Left',
                            'displayValue': 'Left'
                        },
                        {
                            'value': 'Right',
                            'displayValue': 'Right'
                        }
                    ]
                },
                value: 'Center',
                source: 'customizer',
                label: 'Model Slider Position'
            }
        },
        {
            "id": "frontpage_new_vehicle_section_img_upload",
            "config": {
                elementType: 'input',
                elementConfig: {
                    type: 'file'
                },
                value: '',
                source: 'customizer',
                label: 'Section Image - Only shows for Model Slider - Upload'
            }
        },
        {
            "id": "frontpage_new_vehicle_section_img",
            "config": {
                elementType: 'image',
                elementConfig: {
                    width: '50%'
                },
                value: '',
                source: 'customizer',
                label: 'Section Image - Only shows for Model Slider'
            },
        },
        {
            "id": "frontpage_new_vehicle_section_jellybean_upload",
            "config": {
                elementType: 'input',
                elementConfig: {
                    type: 'file'
                },
                value: '',
                source: 'customizer',
                label: 'Jellybean Image - Only shows for Model Slider - Upload'
            }
        },
        {
            "id": "frontpage_new_vehicle_section_jellybean",
            "config": {
                elementType: 'image',
                elementConfig: {
                    width: '50%',
                },
                value: '',
                source: 'customizer',
                label: 'Jellybean Image - Only shows for Model Slider'
            },
        },
        {
            "id": "frontpage_new_vehicle_section_title",
            "config": {
                elementType: 'input',
                elementConfig: {
                    type: 'text'
                },
                value: '',
                source: 'customizer',
                label: 'Section Title - Only shows for Model Slider'
            }
        }
    ]
}

export default homepageNewVehicleModelThemes;