
const getInitFormData = () => {

    const tooltipCSS = {
        border: 'solid 2px black',
        padding: '15px',
    };

    const data = {
        newSiteData: {
            title: 'Site Data',
            desc: 'Some Instructional Text',
            items: [
                {
                    id: "siteName",
                    config: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Site Name',
                            tooltip: {
                                tooltipHTML: `<div>The human readable name of the site - such as <i>Infiniti of Glendora</i>.
<br><br> <span style="color: orange">Note: This form will attempt to generate a URL based on the value provided for this field.</span></div>`,
                                tooltipCSS,
                            }
                        },
                        value: '',
                        source: 'sites',
                        label: 'Site Name:'
                    }
                },
                {
                    id: "siteURL",
                    config: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Site URL',
                            disabled: false,
                            tooltip: {
                                tooltipHTML: `<div>The internal Fox Dealer URL. This will be automatically generated based on the site name provided, but in some cases you may have to edit. 
Make sure the domain is setup in the following format <span style="color:orange;">{domain}.foxdealersites.com</span>.<br><br> <b>Example:</b> <i>sometoyotasite.foxdealersites.com</i></div> <br><br> <b>Must be unique.</b>`,
                                tooltipCSS,
                            }
                        },
                        value: '',
                        source: 'sites',
                        label: 'Site Address (URL):'
                    }
                },
                {
                    id: "admin_email",
                    config: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'email',
                            placeholder: 'Email',
                            disabled: true
                        },
                        value: 'tech@foxdealer.com',
                        source: 'option',
                        label: 'Email:'
                    }
                },
                {
                    "id": "blankTheme",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox"
                        },
                        value: false,
                        source: 'none',
                        label: 'Blank Theme'
                    }
                },
                {
                    id: "fdi_parent_theme",
                    config: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: '92113',
                                    displayValue: 'theme_201'
                                }
                            ]
                        },
                        value: '92113',
                        source: 'option',
                        label: 'Parent Source:'
                    }
                },
                {
                    id: "fdi_oem_program",
                    config: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'none',
                                    displayValue: 'None'
                                },
                                {
                                    value: 'FDINissanProgram',
                                    displayValue: 'Nissan'
                                },
                                {
                                    value: 'FDIInfinitiProgram',
                                    displayValue: 'INFINITI'
                                },
                                {
                                    value: 'FDIToyotaProgram',
                                    displayValue: 'Toyota'
                                },
                                {
                                    value: 'Lexus',
                                    displayValue: 'Lexus'
                                },
                                {
                                    value: 'Honda',
                                    displayValue: 'Honda'
                                },
                                {
                                    value: 'Acura',
                                    displayValue: 'Acura'
                                },
                                {
                                    value: 'FDIGMCanadaProgram',
                                    displayValue: 'GMCC'
                                },
                                {
                                    value: 'FDIHarleyProgram',
                                    displayValue: 'Harley-Davidson'
                                }

                            ],
                            tooltip: {
                                tooltipHTML: `<div>The OEM program for this dealer, if applicable.</div>`,
                                tooltipCSS,
                            }
                        },
                        value: 'None',
                        source: 'option',
                        label: 'OEM:'
                    }
                }
            ]
        },
        themeSettings: {
            title: "Theme Settings",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "site_language",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'en',
                                    displayValue: 'en'
                                },
                                {
                                    value: 'es',
                                    displayValue: 'es'
                                }
                            ]
                        },
                        value: 'en',
                        source: 'customizer',
                        label: 'Site Language:'
                    }
                },
                {
                    "id": "site_units",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'imperial',
                                    displayValue: 'imperial'
                                },
                                {
                                    value: 'metric',
                                    displayValue: 'metric'
                                }
                            ],
                            tooltip: {
                                tooltipHTML: `<div>Imperial units of measurement are <i>miles</i> and are used in the 
United States. Metric units are <i>kilometers</i> and are used around the world. <br><br>This is used when generating disclaimers and affects how certain elements are formatted such as dates.</div>`,
                                tooltipCSS,
                            }
                        },
                        value: 'imperial',
                        source: 'customizer',
                        label: 'Site Units:'
                    }
                },
                {
                    "id": "logo_primary_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Logo Upload:'
                    },
                },
                {
                    "id": "logo_primary",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%',
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Logo Image:'
                    },
                },
                {
                    "id": "logo_secondary_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Logo Upload:'
                    }
                },
                {
                    "id": "logo_secondary",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%',
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Logo Image:'
                    },
                },
                {
                    "id": "font_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'none',
                                    displayValue: 'none'
                                },
                                {
                                    value: 'Toyota_Type',
                                    displayValue: 'Toyota_Type'
                                },
                                {
                                    value: 'Nissan',
                                    displayValue: 'Nissan'
                                },
                                {
                                    value: 'Infiniti',
                                    displayValue: 'Infiniti'
                                },
                                {
                                    value: 'Cadillac_Sans',
                                    displayValue: 'Cadillac_Sans'
                                },
                                {
                                    value: 'Harley-Davidson',
                                    displayValue: 'Harley-Davidson'
                                },
                                {
                                    value: 'Chevy',
                                    displayValue: 'Chevy'
                                },
                                {
                                    value: 'Open Sans',
                                    displayValue: 'Open Sans'
                                },
                                {
                                    value: 'ASAP',
                                    displayValue: 'ASAP'
                                },
                                {
                                    value: 'Proza Libre',
                                    displayValue: 'Proza Libre'
                                },
                                {
                                    value: 'Interstate',
                                    displayValue: 'Interstate'
                                },
                                {
                                    value: 'Montserrat',
                                    displayValue: 'Montserrat'
                                },
                                {
                                    value: 'Franklin Gothic ATF',
                                    displayValue: 'Franklin Gothic ATF'
                                }
                            ]
                        },
                        value: 'none',
                        source: 'customizer',
                        label: 'Primary Font:'
                    }
                },
                {
                    "id": "color_primary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#f7911d',
                        source: 'customizer',
                        label: 'Brand Primary:'
                    }
                },
                {
                    "id": "color_secondary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#000000',
                        source: 'customizer',
                        label: 'Brand Secondary:'
                    }
                },
                {
                    "id": "color_header_bg",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#ffffff',
                        source: 'customizer',
                        label: 'Header Background Color:'
                    }
                },
                {
                    "id": "Color_header_color",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#000000',
                        source: 'customizer',
                        label: 'Header Text & Link Color:'
                    }
                },
                {
                    "id": "color_navbar_bg",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#000000',
                        source: 'customizer',
                        label: 'Navbar Background Color:'
                    }
                },
                {
                    "id": "color_secondary_navbar_bg",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#f7911d',
                        source: 'customizer',
                        label: 'Secondary Navbar Background Color:'
                    }
                },
                {
                    "id": "is_group_site",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Is Group Site:'
                    }
                },
                {
                    "id": "hide_starting_at_main_nav",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: ' Hide \'Starting at $\' in Main Nav Jellybeans'
                    }
                },
                {
                    "id": "hide_starting_at_new_vehicles",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide \'Starting at $\' in New Vehicles Jellybeans'
                    }
                },
                {
                    "id": "autotype_1",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Autotype 1:'
                    }
                },
                {
                    "id": "autotype_2",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Autotype 2:'
                    }
                },
                {
                    "id": "autotype_3",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Autotype 3:'
                    }
                },
                {
                    "id": "search__restrict_by_type",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Restrict Search to New OR Used'
                    }
                },
                {
                    "id": "translate_to_spanish_btn",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: ' Include Translate to Spanish Button'
                    }
                },
                {
                    "id": "translate_to_spanish_btn_text",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: 'ESPAÑOL',
                        source: 'customizer',
                        label: 'Translate Button Text:'
                    }
                },
                {
                    "id": "translate_to_spanish_url",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Spanish site URL'
                    }
                }

            ]
        },
        contactInfoPrimaryHours: {
            title: "Contact Information - Primary Hours",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "hours_title_primary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Title:'
                    }
                },
                {
                    "id": "hours_primary_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'Sales',
                                    'displayValue': 'Sales'
                                },
                                {
                                    'value': 'Service',
                                    'displayValue': 'Service'
                                },
                                {
                                    'value': 'Parts',
                                    'displayValue': 'Parts'
                                },
                                {
                                    'value': 'Collision',
                                    'displayValue': 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Title:'
                    }
                },
                {
                    "id": "hours_open_monday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Monday Open'
                    }
                },
                {
                    "id": "hours_close_monday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Monday Close:'
                    }
                },
                {
                    "id": "hours_open_tuesday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Tuesday Open:'
                    }
                },
                {
                    "id": "hours_close_tuesday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Tuesday Close:'
                    }
                },
                {
                    "id": "hours_open_wednesday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Wednesday Open:'
                    }
                },
                {
                    "id": "hours_close_wednesday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Wednesday Close:'
                    }
                },
                {
                    "id": "hours_open_thursday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Thursday Open:'
                    }
                },
                {
                    "id": "hours_close_thursday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Thursday Close:'
                    }
                },
                {
                    "id": "hours_open_friday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Friday Open:'
                    }
                },
                {
                    "id": "hours_close_friday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Friday Close:'
                    }
                },
                {
                    "id": "hours_open_saturday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Saturday Open:'
                    }
                },
                {
                    "id": "hours_close_saturday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Saturday Close:'
                    }
                },
                {
                    "id": "hours_open_sunday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Sunday Open:'
                    }
                },
                {
                    "id": "hours_close_sunday_primary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Primary Hours Sunday Close:'
                    }
                }
            ]
        },
        contactInfoSecondaryHours: {
            title: "Contact Information - Secondary Hours",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "hours_title_secondary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Title:'
                    }
                },
                {
                    "id": "hours_secondary_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'Sales',
                                    'displayValue': 'Sales'
                                },
                                {
                                    'value': 'Service',
                                    'displayValue': 'Service'
                                },
                                {
                                    'value': 'Parts',
                                    'displayValue': 'Parts'
                                },
                                {
                                    'value': 'Collision',
                                    'displayValue': 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Title:'
                    }
                },
                {
                    "id": "hours_open_monday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Monday Open'
                    }
                },
                {
                    "id": "hours_close_monday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Monday Close:'
                    }
                },
                {
                    "id": "hours_open_tuesday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Tuesday Open:'
                    }
                },
                {
                    "id": "hours_close_tuesday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Tuesday Close:'
                    }
                },
                {
                    "id": "hours_open_wednesday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Wednesday Open:'
                    }
                },
                {
                    "id": "hours_close_wednesday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Wednesday Close:'
                    }
                },
                {
                    "id": "hours_open_thursday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Thursday Open:'
                    }
                },
                {
                    "id": "hours_close_thursday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Thursday Close:'
                    }
                },
                {
                    "id": "hours_open_friday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Friday Open:'
                    }
                },
                {
                    "id": "hours_close_friday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Friday Close:'
                    }
                },
                {
                    "id": "hours_open_saturday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Saturday Open:'
                    }
                },
                {
                    "id": "hours_close_saturday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Saturday Close:'
                    }
                },
                {
                    "id": "hours_open_sunday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Sunday Open:'
                    }
                },
                {
                    "id": "hours_close_sunday_secondary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Secondary Hours Sunday Close:'
                    }
                }
            ]
        },
        contactInfoTertiaryHours: {
            title: "Contact Information - Tertiary Hours",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "hours_title_tertiary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Title:'
                    }
                },
                {
                    "id": "hours_tertiary_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'Sales',
                                    'displayValue': 'Sales'
                                },
                                {
                                    'value': 'Service',
                                    'displayValue': 'Service'
                                },
                                {
                                    'value': 'Parts',
                                    'displayValue': 'Parts'
                                },
                                {
                                    'value': 'Collision',
                                    'displayValue': 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Title:'
                    }
                },
                {
                    "id": "hours_open_monday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Monday Open'
                    }
                },
                {
                    "id": "hours_close_monday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Monday Close:'
                    }
                },
                {
                    "id": "hours_open_tuesday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Tuesday Open:'
                    }
                },
                {
                    "id": "hours_close_tuesday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Tuesday Close:'
                    }
                },
                {
                    "id": "hours_open_wednesday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Wednesday Open:'
                    }
                },
                {
                    "id": "hours_close_wednesday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Wednesday Close:'
                    }
                },
                {
                    "id": "hours_open_thursday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Thursday Open:'
                    }
                },
                {
                    "id": "hours_close_thursday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Thursday Close:'
                    }
                },
                {
                    "id": "hours_open_friday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Friday Open:'
                    }
                },
                {
                    "id": "hours_close_friday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Friday Close:'
                    }
                },
                {
                    "id": "hours_open_saturday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Saturday Open:'
                    }
                },
                {
                    "id": "hours_close_saturday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Saturday Close:'
                    }
                },
                {
                    "id": "hours_open_sunday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Sunday Open:'
                    }
                },
                {
                    "id": "hours_close_sunday_tertiary",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Tertiary Hours Sunday Close:'
                    }
                }
            ]
        },
        contactInfoFourthHours: {
            title: "Contact Information - Fourth Hours",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "hours_title_fourth",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Title:'
                    }
                },
                {
                    "id": "hours_fourth_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'Sales',
                                    'displayValue': 'Sales'
                                },
                                {
                                    'value': 'Service',
                                    'displayValue': 'Service'
                                },
                                {
                                    'value': 'Parts',
                                    'displayValue': 'Parts'
                                },
                                {
                                    'value': 'Collision',
                                    'displayValue': 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Title:'
                    }
                },
                {
                    "id": "hours_open_monday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Monday Open'
                    }
                },
                {
                    "id": "hours_close_monday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Monday Close:'
                    }
                },
                {
                    "id": "hours_open_tuesday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Tuesday Open:'
                    }
                },
                {
                    "id": "hours_close_tuesday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Tuesday Close:'
                    }
                },
                {
                    "id": "hours_open_wednesday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Wednesday Open:'
                    }
                },
                {
                    "id": "hours_close_wednesday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Wednesday Close:'
                    }
                },
                {
                    "id": "hours_open_thursday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Thursday Open:'
                    }
                },
                {
                    "id": "hours_close_thursday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Thursday Close:'
                    }
                },
                {
                    "id": "hours_open_friday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Friday Open:'
                    }
                },
                {
                    "id": "hours_close_friday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Friday Close:'
                    }
                },
                {
                    "id": "hours_open_saturday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Saturday Open:'
                    }
                },
                {
                    "id": "hours_close_saturday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Saturday Close:'
                    }
                },
                {
                    "id": "hours_open_sunday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00AM",
                                    'displayValue': "6:00AM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30AM",
                                    'displayValue': "6:30AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00AM",
                                    'displayValue': "7:00AM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30AM",
                                    'displayValue': "7:30AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00AM",
                                    'displayValue': "8:00AM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30AM",
                                    'displayValue': "8:30AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00AM",
                                    'displayValue': "9:00AM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30AM",
                                    'displayValue': "9:30AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00AM",
                                    'displayValue': "10:00AM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30AM",
                                    'displayValue': "10:30AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00AM",
                                    'displayValue': "11:00AM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30AM",
                                    'displayValue': "11:30AM"
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Sunday Open:'
                    }
                },
                {
                    "id": "hours_close_sunday_fourth",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': "",
                                    'displayValue': ""
                                },
                                {
                                    'value': "12<span class='colon'>:</span>00PM",
                                    'displayValue': "12:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>00PM",
                                    'displayValue': "1:00PM"
                                },
                                {
                                    'value': "1<span class='colon'>:</span>30PM",
                                    'displayValue': "1:30PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>00PM",
                                    'displayValue': "2:00PM"
                                },
                                {
                                    'value': "2<span class='colon'>:</span>30PM",
                                    'displayValue': "2:30PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>00PM",
                                    'displayValue': "3:00PM"
                                },
                                {
                                    'value': "3<span class='colon'>:</span>30PM",
                                    'displayValue': "3:30PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>00PM",
                                    'displayValue': "4:00PM"
                                },
                                {
                                    'value': "4<span class='colon'>:</span>30PM",
                                    'displayValue': "4:30PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>00PM",
                                    'displayValue': "5:00PM"
                                },
                                {
                                    'value': "5<span class='colon'>:</span>30PM",
                                    'displayValue': "5:30PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>00PM",
                                    'displayValue': "6:00PM"
                                },
                                {
                                    'value': "6<span class='colon'>:</span>30PM",
                                    'displayValue': "6:30PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>00PM",
                                    'displayValue': "7:00PM"
                                },
                                {
                                    'value': "7<span class='colon'>:</span>30PM",
                                    'displayValue': "7:30PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>00PM",
                                    'displayValue': "8:00PM"
                                },
                                {
                                    'value': "8<span class='colon'>:</span>30PM",
                                    'displayValue': "8:30PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>00PM",
                                    'displayValue': "9:00PM"
                                },
                                {
                                    'value': "9<span class='colon'>:</span>30PM",
                                    'displayValue': "9:30PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>00PM",
                                    'displayValue': "10:00PM"
                                },
                                {
                                    'value': "10<span class='colon'>:</span>30PM",
                                    'displayValue': "10:30PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>00PM",
                                    'displayValue': "11:00PM"
                                },
                                {
                                    'value': "11<span class='colon'>:</span>30PM",
                                    'displayValue': "11:30PM"
                                },
                                {
                                    'value': "Closed",
                                    'displayValue': "Closed"
                                }
                            ]
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Fourth Hours Sunday Close:'
                    }
                }
            ]
        },
        contactInfoSocial: {
            title: "Contact Information - Social",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "social_url_facebook",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Facebook'
                    }
                },
                {
                    "id": "social_url_twitter",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Twitter'
                    }
                },
                {
                    "id": "social_url_instagram",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Instagram'
                    }
                },
                {
                    "id": "social_url_yelp",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Yelp'
                    }
                },
                {
                    "id": "social_url_youtube",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Youtube'
                    }
                },
                {
                    "id": "social_url_googleplus",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Google Plus'
                    }
                },
                {
                    "id": "social_url_foursquare",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Foursquare'
                    }
                },
                {
                    "id": "social_url_dealerrater",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Dealerrater'
                    }
                },
                {
                    "id": "social_url_pintrest",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Pintrest'
                    }
                },
                {
                    "id": "social_url_flickr",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'url'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Flickr'
                    }
                },
            ]
        },
        contactInfoPrivacy: {
            title: "Contact Information - Privacy Act Contact Info",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "privacy_act__name",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: 'Fox Dealer',
                        source: 'customizer',
                        label: 'Name - Privacy Act'
                    }
                },
                {
                    "id": "privacy_act__phone",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '(866) 391-1718',
                        source: 'customizer',
                        label: 'Phone - Privacy Act'
                    }
                },
                {
                    "id": "privacy_act__email",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: 'support@foxdealer.com',
                        source: 'customizer',
                        label: 'Email - Privacy Act'
                    }
                },
                {
                    "id": "privacy_act__address",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '150 South Arroyo Parkway Second Floor, Pasadena, CA 91105',
                        source: 'customizer',
                        label: 'Address - Privacy Act'
                    }
                }
            ]
        },
        headerSettings: {
            title: "Header",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "main_navigation_sticky",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Stick Main Nav to top on scroll'
                    }
                },
                {
                    "id": "mobile_contact_info_sticky",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Stick Mobile Contact Info to top on scroll'
                    }
                },
                {
                    "id": "header_bg_img_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Background Image Upload'
                    }
                },
                {
                    "id": "header_bg_img",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Background Image'
                    }
                },
                {
                    "id": "header_contact_layout",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'stacked',
                                    'displayValue': 'stacked'
                                },
                                {
                                    'value': 'shared',
                                    'displayValue': 'shared'
                                }
                            ]
                        },
                        value: 'stacked',
                        source: 'customizer',
                        label: 'Header Contact Layout'
                    }
                },
                {
                    "id": "header_search_as_nav",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Search Button as Nav Item'
                    }
                },
                {
                    "id": "header_search_theme",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'default',
                                    'displayValue': 'default'
                                },
                                {
                                    'value': 'Replace Header',
                                    'displayValue': 'Replace Header'
                                }
                            ]
                        },
                        value: 'default',
                        source: 'customizer',
                        label: 'Header Contact Layout'
                    }
                },
                {
                    "id": "header_include_secondary_phone",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Include Secondary Phone'
                    }
                },
                {
                    "id": "header_include_tertiary_phone",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: ' Include Tertiary Phone'
                    }
                },
                {
                    "id": "header_new_vehicle_dropdown_style",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'wide',
                                    'displayValue': 'wide'
                                },
                                {
                                    'value': 'tall',
                                    'displayValue': 'tall'
                                },
                                {
                                    'value': 'none',
                                    'displayValue': 'none'
                                },
                                {
                                    'value': 'show_category_img',
                                    'displayValue': 'show_category_img'
                                }
                            ]
                        },
                        value: 'wide',
                        source: 'customizer',
                        label: 'New Vehicle Dropdown Style'
                    }
                }
            ]
        },
        footerSettings: {
            title: "Footer",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "footer_theme",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'dark',
                                    'displayValue': 'dark'
                                },
                                {
                                    'value': 'light',
                                    'displayValue': 'light'
                                }
                            ]
                        },
                        value: 'dark',
                        source: 'customizer',
                        label: 'Footer Layout'
                    }
                },
                {
                    "id": "foxlogo__footer",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'dark',
                                    'displayValue': 'dark'
                                },
                                {
                                    'value': 'light',
                                    'displayValue': 'light'
                                }
                            ]
                        },
                        value: 'light',
                        source: 'customizer',
                        label: 'Fox Logo - Footer'
                    }
                },
                {
                    "id": "footer_logo",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'primary',
                                    'displayValue': 'primary'
                                },
                                {
                                    'value': 'secondary',
                                    'displayValue': 'secondary'
                                }
                            ]
                        },
                        value: 'primary',
                        source: 'customizer',
                        label: 'Brand Footer Logo'
                    }
                },
                {
                    "id": "footer_include_secondary_phone",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: ' Include Secondary Phone'
                    }
                },
                {
                    "id": "footer_include_tertiary_phone",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Include Tertiary Phone'
                    }
                }

            ]
        },
        homepageNewVehicleModel: {
            title: "Homepage Layout - New Vehicle Module",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "frontpage_new_vehicle_module",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'none',
                                    'displayValue': 'none'
                                },
                                {
                                    'value': 'Parallax',
                                    'displayValue': 'Parallax'
                                },
                                {
                                    'value': 'Model Slider',
                                    'displayValue': 'Model Slider'
                                }
                            ]
                        },
                        value: 'none',
                        source: 'customizer',
                        label: 'New Vehicle Module'
                    }
                },
                {
                    "id": "frontpage_new_vehicle_jellybean_fly_in_direction",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'fly-in--right',
                                    'displayValue': 'fly-in--right'
                                },
                                {
                                    'value': 'fly-in--left',
                                    'displayValue': 'fly-in--left'
                                }
                            ]
                        },
                        value: 'fly-in--right',
                        source: 'customizer',
                        label: 'Jellybean Reveal Animation'
                    }
                }

            ]
        },
        homepageAbout: {
            title: "Homepage Layout - About Us",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "frontpage_about_us_style",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'shared',
                                    'displayValue': 'shared'
                                },
                                {
                                    'value': 'stacked',
                                    'displayValue': 'stacked'
                                }
                            ]
                        },
                        value: 'stacked',
                        source: 'customizer',
                        label: 'About Us Style'
                    }
                },
                {
                    "id": "frontpage_about_us_title",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'About Us Title'
                    }
                },
                {
                    "id": "frontpage_about_us_subtitle",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'About Us Subtitle'
                    }
                },
                {
                    "id": "frontpage_about_us_copy",
                    "config": {
                        elementType: 'textarea',
                        elementConfig: {},
                        value: '',
                        source: 'customizer',
                        label: 'About Us Copy'
                    }
                },
                {
                    "id": "frontpage_about_us_section_img_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Section Image Upload'
                    }
                },
                {
                    "id": "frontpage_about_us_section_img",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%',
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Section Image'
                    },
                },
                {
                    "id": "frontpage_about_us_section_img_location",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'Top',
                                    'displayValue': 'Top'
                                },
                                {
                                    'value': 'Overlay Top',
                                    'displayValue': 'Overlay Top'
                                },
                                {
                                    'value': 'Overlay Center',
                                    'displayValue': 'Overlay Center'
                                },
                                {
                                    'value': 'Bottom',
                                    'displayValue': 'Bottom'
                                },
                                {
                                    'value': 'Overlay Bottom',
                                    'displayValue': 'Overlay Bottom'
                                }
                            ]
                        },
                        value: 'Overlay Top',
                        source: 'customizer',
                        label: 'Section Image Location'
                    }
                },
                {
                    "id": "frontpage_about_us_bg_color",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#000000',
                        source: 'customizer',
                        label: 'About Us BG color'
                    }
                },
                {
                    "id": "frontpage_about_us_text_color",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#ffffff',
                        source: 'customizer',
                        label: 'About Us Text color'
                    }
                }
            ]
        },
        homepageContactMap: {
            title: "Homepage Layout - Contact & Map Section",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "frontpage_contact_us_style",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'stacked',
                                    'displayValue': 'stacked'
                                },
                                {
                                    'value': 'shared',
                                    'displayValue': 'shared'
                                },
                                {
                                    'value': 'hidden',
                                    'displayValue': 'hidden'
                                }
                            ]
                        },
                        value: 'stacked',
                        source: 'customizer',
                        label: 'Contact Us / Map Style'
                    }
                },
                {
                    "id": "frontpage_contact_us_hide_general",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide Location and Phone'
                    }
                },
                {
                    "id": "frontpage_contact_us_hide_hours_primary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide Primary Hours'
                    }
                },
                {
                    "id": "frontpage_contact_us_hide_hours_secondary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide Secondary Hours'
                    }
                },
                {
                    "id": "frontpage_contact_us_bg_img_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Contact Us BG Image Upload'
                    }
                },
                {
                    "id": "frontpage_contact_us_bg_img",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%',
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Contact Us BG Image'
                    },
                },
                {
                    "id": "frontpage_contact_us_text_color",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#000000',
                        source: 'customizer',
                        label: 'Contact Us Text Color'
                    }
                },
                {
                    "id": "frontpage_contact_us_bg_color_primary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#f7911d',
                        source: 'customizer',
                        label: 'Contact Us BG Color - Primary'
                    }
                },
                {
                    "id": "frontpage_contact_us_bg_color_secondary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'color'
                        },
                        value: '#f7911d',
                        source: 'customizer',
                        label: 'Contact Us BG Color - Secondary'
                    }
                },
                {
                    "id": "frontpage_contact_us_bg_gradient_style",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'linear',
                                    'displayValue': 'linear'
                                },
                                {
                                    'value': 'radial',
                                    'displayValue': 'radial'
                                }
                            ]
                        },
                        value: 'linear',
                        source: 'customizer',
                        label: 'Contact Us BG Color - Gradient Style'
                    }
                },
                {
                    "id": "frontpage_contact_us_bg_gradient_type",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': '0deg',
                                    'displayValue': '0deg'
                                },
                                {
                                    'value': '90deg',
                                    'displayValue': '90deg'
                                },
                                {
                                    'value': '180deg',
                                    'displayValue': '180deg'
                                },
                                {
                                    'value': '270deg',
                                    'displayValue': '270deg'
                                },
                                {
                                    'value': 'ellipse',
                                    'displayValue': 'ellipse'
                                },
                                {
                                    'value': 'circle',
                                    'displayValue': 'circle'
                                }
                            ]
                        },
                        value: '0deg',
                        source: 'customizer',
                        label: 'Contact Us BG Color - Gradient Type'
                    }
                },
                {
                    "id": "frontpage_contact_us_section_img_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Contact Section Image Upload'
                    }
                },
                {
                    "id": "frontpage_contact_us_section_img",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%',
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Contact Section Image'
                    },
                },
                {
                    "id": "frontpage_mapSection_desktop_show",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Show Map on Desktop'
                    }
                },
                {
                    "id": "maps__custom_geopin_upload",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Custom Geo Pin Upload'
                    }
                },
                {
                    "id": "maps__custom_geopin",
                    "config": {
                        elementType: 'image',
                        elementConfig: {
                            width: '50%',
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Custom Geo Pin'
                    },
                },
                {
                    "id": "frontpage_mapSection_phone_show",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Show Map on Phone'
                    }
                },
                {
                    "id": "frontpage_mapSection_logo_show",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Show Logo on Map'
                    }
                },
                {
                    "id": "frontpage_map_style",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    'value': 'top',
                                    'displayValue': 'top'
                                },
                                {
                                    'value': 'center',
                                    'displayValue': 'center'
                                }
                            ]
                        },
                        value: 'top',
                        source: 'customizer',
                        label: 'Map / Directions Style'
                    }
                },
            ]
        },
        pricingOptions: {
            title: "Inventory Options - Pricing Options",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "price_stack__use_html",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            disabled: true,
                            type: 'text',
                            placeholder: 'False'
                        },
                        value: 'false',
                        source: 'customizer',
                        label: 'Use OLD HTML Price Stack'
                    }
                },
                {
                    "id": "vdp_hide_msrp",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Hide Original Price'
                    }
                },
                {
                    "id": "vdp_hide_dealer_discount",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Hide Dealer Discount'
                    }
                },
                {
                    "id": "vdp_show_selling_price",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP SHOW Selling Price'
                    }
                },
                {
                    "id": "vdp_hide_incentives",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Hide Rebates / Incentives'
                    }
                },
                {
                    "id": "vdp_show_total_incentives",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP SHOW Total Incentives'
                    }
                },
                {
                    "id": "vdp_hide_total_savings",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Hide Total Savings'
                    }
                },
                {
                    "id": "show_conditional_cash_incentives",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Show Conditional Cash Incentives'
                    }
                },
                {
                    "id": "vdp_show_conditional_price",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP SHOW Conditional Price Total'
                    }
                },
                {
                    "id": "vdp_hide_final_price",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide Final Price *Always'
                    }
                },
                {
                    "id": "vdp_hide_final_price_if_discounted",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide Final Price *ONLY* if discounted'
                    }
                },
                {
                    "id": "vdp_hide_addons",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP HIDE Add Ons'
                    }
                },
                {
                    "id": "vdp_show_total_addons",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP SHOW Total Add Ons'
                    }
                },
                {
                    "id": "vdp_show_total_addons_text",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox'
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Add Ons Text'
                    }
                },
                {
                    "id": "pricing_field_msrp",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text'
                        },
                        value: 'MSRP',
                        source: 'customizer',
                        label: 'MSRP - The template tag for the highest price. Default is MSRP'
                    }
                },
                {
                    "id": "pricing_field_final_price",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'msrp',
                                    displayValue: 'msrp'
                                },
                                {
                                    value: 'sellingprice',
                                    displayValue: 'sellingprice'
                                },
                                {
                                    value: 'display_price',
                                    displayValue: 'display_price'
                                },
                                {
                                    value: 'comment1',
                                    displayValue: 'comment1'
                                },
                                {
                                    value: 'comment2',
                                    displayValue: 'comment2'
                                },
                                {
                                    value: 'comment3',
                                    displayValue: 'comment3'
                                },
                                {
                                    value: 'comment4',
                                    displayValue: 'comment4'
                                },
                                {
                                    value: 'comment5',
                                    displayValue: 'comment5'
                                }
                            ]
                        },
                        value: 'display_price',
                        source: 'customizer',
                        label: 'Displayed Price - The template tag for the lowest price. Default is display_price'
                    }
                }
            ]
        },
        windowSticker: {
            title: "Inventory Options - Window Sticker",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "show_window_sticker",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'none',
                                    displayValue: 'none'
                                },
                                {
                                    value: 'all',
                                    displayValue: 'all'
                                },
                                {
                                    value: 'after_pricing',
                                    displayValue: 'after_pricing'
                                },
                                {
                                    value: 'after_carousel_thumbs',
                                    displayValue: 'after_carousel_thumbs'
                                }
                            ]
                        },
                        value: 'none',
                        source: 'customizer',
                        label: 'Show Window Sticker'
                    }
                }
            ]
        },
        srpOptions: {
            title: "Inventory Options - SRP Options",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "srp_banner__type",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Default',
                                    displayValue: 'Default'
                                },
                                {
                                    value: 'Inline Form',
                                    displayValue: 'Inline Form'
                                }
                            ]
                        },
                        value: 'Default',
                        source: 'customizer',
                        label: 'SRP Banner Type'
                    }
                },
                {
                    "id": "srp_page_width",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'default',
                                    displayValue: 'default'
                                },
                                {
                                    value: 'limited',
                                    displayValue: 'limited'
                                }
                            ]
                        },
                        value: 'default',
                        source: 'customizer',
                        label: 'SRP Page Width'
                    }
                },
                {
                    "id": "carbox_show_stock_vin",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: true,
                                    displayValue: 'true'
                                },
                                {
                                    value: false,
                                    displayValue: 'false'
                                }
                            ]
                        },
                        value: true,
                        source: 'customizer',
                        label: 'Show Stock and VIN on Carbox'
                    }
                },
                {
                    "id": "carbox_show_priority_options_icons",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: true,
                                    displayValue: 'true'
                                },
                                {
                                    value: false,
                                    displayValue: 'false'
                                }
                            ]
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Carbox - Show Priority Options Icons'
                    }
                },
                {
                    "id": "carbox_show_text_specs",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: true,
                                    displayValue: 'true'
                                },
                                {
                                    value: false,
                                    displayValue: 'false'
                                }
                            ]
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Carbox - Show Text Specs'
                    }
                },
                {
                    "id": "carbox_overlay_type",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'none',
                                    displayValue: 'none'
                                },
                                {
                                    value: 'VDP CTA',
                                    displayValue: 'VDP CTA'
                                },
                                {
                                    value: 'Quick View CTA',
                                    displayValue: 'Quick View CTA'
                                },
                                {
                                    value: 'Image Only - Quick View',
                                    displayValue: 'Image Only - Quick View'
                                },
                                {
                                    value: 'Summary Info',
                                    displayValue: 'Summary Info'
                                },
                                {
                                    value: 'form',
                                    displayValue: 'form'
                                }
                            ]
                        },
                        value: 'Quick View CTA',
                        source: 'customizer',
                        label: 'Carbox Overlay'
                    }
                },
                {
                    "id": "carbox_inline_form",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox"
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Carbox Inline Form'
                    }
                },
                {
                    "id": "srp_banner_disclaimer",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'button',
                                    displayValue: 'button'
                                },
                                {
                                    value: 'below',
                                    displayValue: 'below'
                                }
                            ]
                        },
                        value: 'button',
                        source: 'customizer',
                        label: 'Banner Disclaimer Type'
                    }
                },
                {
                    "id": "srp_price_filter_type",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'dropdown',
                                    displayValue: 'dropdown'
                                },
                                {
                                    value: 'default',
                                    displayValue: 'default'
                                },
                                {
                                    value: 'slider',
                                    displayValue: 'slider'
                                }
                            ]
                        },
                        value: 'default',
                        source: 'customizer',
                        label: 'Filter - Price Filter Type [ in dev]'
                    }
                },
                {
                    "id": "srp_filter_theme",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'neutral',
                                    displayValue: 'neutral'
                                },
                                {
                                    value: 'boxed',
                                    displayValue: 'boxed'
                                },
                                {
                                    value: 'bold',
                                    displayValue: 'bold'
                                }
                            ]
                        },
                        value: 'boxed',
                        source: 'customizer',
                        label: 'Filter Theme'
                    }
                },
                {
                    "id": "srp_filter_select",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'highlight',
                                    displayValue: 'highlight'
                                },
                                {
                                    value: 'checkbox',
                                    displayValue: 'checkbox'
                                }
                            ]
                        },
                        value: 'highlight',
                        source: 'customizer',
                        label: 'Filter Selects Style'
                    }
                },
                {
                    "id": "srp_pricing_layout",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Top - Bottom',
                                    displayValue: 'Top - Bottom'
                                },
                                {
                                    value: 'Side by Side',
                                    displayValue: 'Side by Side'
                                },
                                {
                                    value: 'Custom',
                                    displayValue: 'Custom'
                                }
                            ]
                        },
                        value: 'Side by Side',
                        source: 'customizer',
                        label: 'Pricing Layout'
                    }
                },
                {
                    "id": "pricing_msrp_strikethrough",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'red',
                                    displayValue: 'red'
                                },
                                {
                                    value: 'currentColor',
                                    displayValue: 'currentColor'
                                },
                                {
                                    value: 'none',
                                    displayValue: 'none'
                                }
                            ]
                        },
                        value: 'red',
                        source: 'customizer',
                        label: 'MSRP Strikethrough'
                    }
                },
                {
                    "id": "quickview_vehicle_carousel_theme",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'default',
                                    displayValue: 'default'
                                },
                                {
                                    value: 'full_width',
                                    displayValue: 'full_width'
                                }
                            ]
                        },
                        value: 'default',
                        source: 'customizer',
                        label: 'Quickview Vehicle Carousel Theme'
                    }
                }
            ]
        },
        vdpOptions: {
            title: "Inventory Options - VDP Options",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "vdp_theme",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'original',
                                    displayValue: 'original'
                                },
                                {
                                    value: 'single_column',
                                    displayValue: 'single_column'
                                }
                            ]
                        },
                        value: 'original',
                        source: 'customizer',
                        label: 'VDP Theme'
                    }
                },
                {
                    "id": "vdp_description",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'description',
                                    displayValue: 'description'
                                },
                                {
                                    value: 'description2',
                                    displayValue: 'description2'
                                }
                            ]
                        },
                        value: 'description',
                        source: 'customizer',
                        label: 'VDP Description Template Tag'
                    }
                },
                {
                    "id": "vdp_quickview_vdp_same_tab",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox"
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Open VDP in same tab as Quickview'
                    }
                },
                {
                    "id": "vdp_cta_button_first_text",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'customizer',
                        label: 'VDP Single Column First CTA Button Text - Default is Ask about this [vehicle]'
                    }
                },
                {
                    "id": "vdp_cta_button_first_text_vehicle_info",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox"
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Single Column First CTA Button Text - Append Vehicle Info'
                    }
                },
                {
                    "id": "vdp_cta_button_second_text",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'customizer',
                        label: 'VDP Single Column Second CTA Button Text - Default is Ask Us Anything'
                    }
                },
                {
                    "id": "vdp_cta_button_second_text_vehicle_info",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox"
                        },
                        value: false,
                        source: 'customizer',
                        label: 'VDP Single Column Second CTA Button Text - Append Vehicle Info'
                    }
                },
                {
                    "id": "vdp_form_primary_hide",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox"
                        },
                        value: false,
                        source: 'customizer',
                        label: 'Hide Primary Fox Form'
                    }
                }
            ]
        },
        incentivesAndSpecials: {
            title: "Inventory Options - Incentives & Specials",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "carbox_show_lease_finance",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox',
                            checked: true
                        },
                        value: true,
                        source: 'customizer',
                        label: 'Carbox - Show Lease / Finance'
                    }
                },
                {
                    "id": "vdp_show_lease_finance",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'checkbox',
                            checked: true
                        },
                        value: true,
                        source: 'customizer',
                        label: 'VDP Show Lease / Finance Info'
                    }
                },
                {
                    "id": "carbox_specials_ribbon_location",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'ribbon__top-left',
                                    displayValue: 'ribbon__top-left'
                                },
                                {
                                    value: 'ribbon__top-right',
                                    displayValue: 'ribbon__top-right'
                                },
                                {
                                    value: 'ribbon__top-above',
                                    displayValue: 'ribbon__top-above'
                                }
                            ]
                        },
                        value: 'ribbon__top-left',
                        source: 'customizer',
                        label: 'Specials Ribbon Location'
                    }
                }
            ]
        },
        disclaimers: {
            title: "Inventory Options - Disclaimers",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "disclaimer_inventory_default_hide",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Show',
                                    displayValue: 'Show'
                                },
                                {
                                    value: 'Hide',
                                    displayValue: 'Hide'
                                }
                            ]
                        },
                        value: 'Show',
                        source: 'customizer',
                        label: 'Show / Hide Default Disclaimer'
                    }
                },
                {
                    "id": "disclaimer_default",
                    "config": {
                        elementType: 'textarea',
                        elementConfig: {
                            type: "text"
                        },
                        value: '<p>All advertised vehicles are subject to actual dealer availability. Certain vehicles listed may not be available, or may have different prices. Prices exclude state tax, license, document preparation fee, smog fee, and finance charges, if applicable. Vehicle option and pricing are subject to change. Prices include all dealer rebates and dealer incentives. Pricing and availability varies by dealership. Please check with your dealer for more information. Prices do not include dealer charges, such as advertising, that can vary by manufacturer or region, or costs for selling, preparing, displaying or financing the vehicle. Images displayed may not be representative of the actual trim level of a vehicle. Colors shown are the most accurate representations available. However, due to the limitations of web and monitor color display, we cannot guarantee that the colors depicted will exactly match the color of the car. Information provided is believed accurate but all specifications, pricing, and availability must be confirmed in writing (directly) with the dealer to be binding. Neither the Dealer nor Fox Dealer Interactive is responsible for any inaccuracies contained herein and by using this application you the customer acknowledge the foregoing and accept such terms.</p><p> Net Price DOES NOT include dealer or vendor installed upgrades, accessories, or added after market items. Those products are OPTIONAL and can be purchased for an additional cost. Call For Details!*<p>Final prices are price shown plus government fees and taxes, any finance charges, any dealer document preparation charge, and any emission testing charge.</p>',
                        source: 'customizer',
                        label: 'Default Disclaimer'
                    }
                },
                {
                    "id": "disclaimer_inventory_new",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'customizer',
                        label: 'New - Disclaimer'
                    }
                },
                {
                    "id": "disclaimer_inventory_used",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'customizer',
                        label: 'Used - Disclaimer'
                    }
                },
                {
                    "id": "disclaimer_gas_mileage",
                    "config": {
                        elementType: 'textarea',
                        elementConfig: {
                            type: "text"
                        },
                        value: 'Based on EPA mileage ratings. Use for comparison purposes only. Your mileage will vary depending on driving conditions, how you drive and maintain your vehicle, battery-pack age/condition, and other factors.',
                        source: 'customizer',
                        label: 'Gas Mileage Disclaimer'
                    }
                },

            ]
        },
        locations: {
            title: "Locations",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "location__name",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Location Name'
                    }
                },
                {
                    "id": "location__logo",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: 'file'
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Location Logo'
                    }
                },
                {
                    "id": "location__street_number",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Street #'
                    }
                },
                {
                    "id": "location__street_name",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Street Name'
                    }
                },
                {
                    "id": "location__city",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'City'
                    }
                },
                {
                    "id": "location__state",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'State'
                    }
                },
                {
                    "id": "location__zip",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Zip'
                    }
                },
                {
                    "id": "location__country",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Country'
                    }
                },
                {
                    "id": "location__home_url",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Home Page'
                    }
                },
                {
                    "id": "location__new_url",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'New Inventory Page'
                    }
                },
                {
                    "id": "location__used_url",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Used Inventory Page'
                    }
                },
                {
                    "id": "location__service_url",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Service Page'
                    }
                },
                {
                    "id": "location__inventory_src",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: '',
                                    displayValue: ''
                                },
                                {
                                    value: 'Homenet',
                                    displayValue: 'Homenet'
                                },
                                {
                                    value: 'ICC',
                                    displayValue: 'ICC'
                                },
                            ]
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Inventory Source'
                    }
                },
                {
                    "id": "location__feed_id",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text",
                            tooltip: {
                                tooltipHTML: `<p>The feed ID associated with this location if this site is displaying multiple stores.</p>`,
                                tooltipCSS,
                            }
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Feed ID'
                    }
                },
                {
                    "id": "location__dealer_id",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Dealer ID'
                    }
                },
                {
                    "id": "location__phone_primary_title",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Primary Title'
                    }
                },
                {
                    "id": "location__phone_primary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Primary'
                    }
                },
                {
                    "id": "location__phone_primary_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Main',
                                    displayValue: 'Main'
                                },
                                {
                                    value: 'Sales',
                                    displayValue: 'Sales'
                                },
                                {
                                    value: 'Service',
                                    displayValue: 'Service'
                                },
                                {
                                    value: 'Parts',
                                    displayValue: 'Parts'
                                },
                                {
                                    value: 'Collision',
                                    displayValue: 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Primary Tag'
                    }
                },
                {
                    "id": "location__phone_secondary_title",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Secondary Title'
                    }
                },
                {
                    "id": "location__phone_secondary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Secondary'
                    }
                },
                {
                    "id": "location__phone_secondary_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Main',
                                    displayValue: 'Main'
                                },
                                {
                                    value: 'Sales',
                                    displayValue: 'Sales'
                                },
                                {
                                    value: 'Service',
                                    displayValue: 'Service'
                                },
                                {
                                    value: 'Parts',
                                    displayValue: 'Parts'
                                },
                                {
                                    value: 'Collision',
                                    displayValue: 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Secondary Tag'
                    }
                },
                {
                    "id": "location__phone_tertiary_title",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Tertiary Title'
                    }
                },
                {
                    "id": "location__phone_tertiary",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Tertiary'
                    }
                },
                {
                    "id": "location__phone_tertiary_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Main',
                                    displayValue: 'Main'
                                },
                                {
                                    value: 'Sales',
                                    displayValue: 'Sales'
                                },
                                {
                                    value: 'Service',
                                    displayValue: 'Service'
                                },
                                {
                                    value: 'Parts',
                                    displayValue: 'Parts'
                                },
                                {
                                    value: 'Collision',
                                    displayValue: 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Tertiary Tag'
                    }
                },
                {
                    "id": "location__phone_fourth_title",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Fourth Title'
                    }
                },
                {
                    "id": "location__phone_fourth",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Fourth'
                    }
                },
                {
                    "id": "location__phone_fourth_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Main',
                                    displayValue: 'Main'
                                },
                                {
                                    value: 'Sales',
                                    displayValue: 'Sales'
                                },
                                {
                                    value: 'Service',
                                    displayValue: 'Service'
                                },
                                {
                                    value: 'Parts',
                                    displayValue: 'Parts'
                                },
                                {
                                    value: 'Collision',
                                    displayValue: 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Fourth Tag'
                    }
                },
                {
                    "id": "location__phone_fifth_title",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Fifth Title'
                    }
                },
                {
                    "id": "location__phone_fifth",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Fifth'
                    }
                },
                {
                    "id": "location__phone_fifth_tag",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'Main',
                                    displayValue: 'Main'
                                },
                                {
                                    value: 'Sales',
                                    displayValue: 'Sales'
                                },
                                {
                                    value: 'Service',
                                    displayValue: 'Service'
                                },
                                {
                                    value: 'Parts',
                                    displayValue: 'Parts'
                                },
                                {
                                    value: 'Collision',
                                    displayValue: 'Collision'
                                }
                            ]
                        },
                        value: '',
                        source: 'locations__ui',
                        label: 'Phone - Fifth Tag'
                    }
                }

            ]
        },
        backend: {
            title: "Backend",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "class",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'FDIHomenetSource',
                                    displayValue: 'HomeNet IOL'
                                },
                                {
                                    value: 'FDIGigiSource',
                                    displayValue: 'Cargigi'
                                },
                                {
                                    value: 'FDIICCSource',
                                    displayValue: 'ICC inControl'
                                },
                                {
                                    value: 'FDIICCWithIncentivesSource',
                                    displayValue: 'ICC inControl 2.5'
                                },
                                {
                                    value: 'FDIICCNextSource',
                                    displayValue: 'ICC inControl 2.0'
                                },
                                {
                                    value: 'FDIR22Source',
                                    displayValue: 'R22'
                                },
                                {
                                    value: 'none',
                                    displayValue: 'None Selected'
                                }
                            ]
                        },
                        value: 'none',
                        source: 'inventory_source',
                        label: 'Provider'
                    }
                },
                {
                    "id": "fdi_allow_inventory_sync",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox",
                            tooltip: {
                                tooltipHTML: `<p>Whether or not the site should sync updates every 15 minutes.</p>`,
                                tooltipCSS,
                            }
                        },
                        value: false,
                        source: 'option',
                        label: 'Allow Inventory Syncing'
                    }
                },
                {
                    "id": "notes",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text"
                        },
                        value: '',
                        source: 'inventory_source',
                        label: 'Inventory Notes'
                    }
                },
                {
                    "id": "fdi_types_mutually_exclusive",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "checkbox",
                            tooltip: {
                                tooltipHTML: `<p>Whether or not to redirect the page from /inventory to /inventory/new. This is useful when the dealer wants to highlight <b>new</b> inventory.</p>`,
                                tooltipCSS,
                            }
                        },
                        value: false,
                        source: 'option',
                        label: 'Redirect /inventory/ to /inventory/new/'
                    }
                },
                {
                    "id": "customSiteTags",
                    "config": {
                        elementType: 'input',
                        elementConfig: {
                            type: "text",
                            tooltip: {
                                tooltipHTML: `<div>Custom site tags separated by a comma. For example: <span style="color: orange;">te-nissan,nwnissan</span>.</div>`,
                                tooltipCSS,
                            }
                        },
                        value: '',
                        source: 'settings_general',
                        label: 'Custom Site Tags'
                    }
                }
            ]
        },
        menus: {
            title: "Menus",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "main-nav",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'mainNav',
                                    displayValue: 'mainNav'
                                },
                                {
                                    value: 'mainNav_Toyota',
                                    displayValue: 'mainNav_Toyota'
                                }
                            ]
                        },
                        value: 'mainNav',
                        source: 'menus',
                        label: 'Main Nav'
                    }
                },
                {
                    "id": "footer-nav-left",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'FooterLeft',
                                    displayValue: 'FooterLeft'
                                }
                            ]
                        },
                        value: 'FooterLeft',
                        source: 'menus',
                        label: 'Footer Nav Left'
                    }
                },
                {
                    "id": "footer-nav-center",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'FooterCenter',
                                    displayValue: 'FooterCenter'
                                }
                            ]
                        },
                        value: 'FooterCenter',
                        source: 'menus',
                        label: 'Footer Nav Center'
                    }
                },
                {
                    "id": "footer-nav-right",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: 'FooterRight',
                                    displayValue: 'FooterRight'
                                },
                                {
                                    value: 'FooterRight_Toyota',
                                    displayValue: 'FooterRight_Toyota'
                                },
                                {
                                    value: 'FooterRight_es_Toyota',
                                    displayValue: 'FooterRight_es_Toyota'
                                }
                            ]
                        },
                        value: 'FooterRight',
                        source: 'menus',
                        label: 'Footer Nav Right'
                    }
                }
            ]
        },
        jellybeans: {
            title: "New Vehicle Jellybeans",
            desc: 'Some Instructional Text',
            items: [
                {
                    "id": "jellybeans_import_site",
                    "config": {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                {
                                    value: '',
                                    displayValue: 'None'
                                },
                            ],
                            tooltip: {
                                tooltipHTML: `<p>Import jelly beans from another site to get started.</p>`,
                                tooltipCSS,
                            }
                        },
                        searchTags: [
                            'jellybean',
                            'images',
                        ],
                        value: '',
                        source: 'jellybeans',
                        label: 'Import Jelly Beans'
                    }
                }
            ]
        }
    }

    return Object.assign( data );
};

export default getInitFormData