const functions = {
    getFileBlob: async ( args ) => {
        let event = args.event;
        if ( event && event.target && event.target.files && event.target.files[0] ){
            async function readFileAsDataURL( file ) {
                return await new Promise(( resolve ) => {
                    let fileReader = new FileReader();
                    fileReader.onload = ( e ) => resolve( fileReader.result );
                    fileReader.readAsDataURL( file );
                });
            }
            return await readFileAsDataURL( event.target.files[0] );
        }
    },
    isUniqueDomain: ({ value, existingDomains }) => {
        let existingDomainsQty = existingDomains.length;
        for ( let i = 0; i < existingDomainsQty; i++ ){
            let domain = existingDomains[i];
            if ( value === domain ){
                return false;
            }
        }
        return true;
    },
};
export default functions;