import React from 'react';
import './FormSection.css';

const FormSection = ({ children, title, description, id }) => {

    return (
        <div className="formSection" id={id}>
            <div className="section__title">
                {title}
            </div>

            {/*<div className="section__desc">*/}
            {/*    {description}*/}
            {/*</div>*/}

            <div className="section__content">
                {children}
            </div>
        </div>
    )
}

export default FormSection;
