import './App.css';
import NewSiteForm from './NewSiteForm/NewSiteForm';

const App = (props) => {
  return (

    <div className="NewSiteForm">
      <NewSiteForm />
    </div>
  );
}

export default App;
