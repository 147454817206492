
import { default as f } from './functions';
const vars = {
    suffix: '.foxdealersites.com',
};
const updateForm = ( formDataIndexMap, existingDomains ) => {

    const self = {
        form: null,
    };

    const data = self => ({
        logo_primary_upload: {
            "*": {
                themeSettings: {
                    items: {
                        logo_primary: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        logo_secondary_upload:{
            "*": {
                themeSettings: {
                    items: {
                        logo_secondary: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        header_bg_img_upload: {
            "*": {
                themeSettings: {
                    items: {
                        header_bg_img: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        frontpage_new_vehicle_section_img_upload: {
            "*": {
                themeSettings: {
                    items: {
                        frontpage_new_vehicle_section_img: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        frontpage_new_vehicle_section_jellybean_upload:{
            "*": {
                themeSettings: {
                    items: {
                        frontpage_new_vehicle_section_jellybean: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        frontpage_about_us_section_img_upload: {
            "*": {
                themeSettings: {
                    items: {
                        frontpage_about_us_section_img: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        frontpage_contact_us_bg_img_upload: {
            "*": {
                themeSettings: {
                    items: {
                        frontpage_contact_us_bg_img: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        frontpage_contact_us_section_img_upload: {
            "*": {
                themeSettings: {
                    items: {
                        frontpage_contact_us_section_img: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        maps__custom_geopin_upload: {
            "*": {
                themeSettings: {
                    items: {
                        maps__custom_geopin: async ( args ) => {
                            return await f.getFileBlob( args );
                        },
                    }
                }
            }
        },
        siteName: {
            "*": {
                newSiteData: {
                    items: {
                        siteURL: ( args ) => {
                            if ( args.hasOwnProperty( 'value' ) ){
                                let newDomain = `${ args.value.toLowerCase().replace(/[^a-z0-9\\-]/g, '' ) }${ vars.suffix }`;
                                args.value = newDomain;
                                let isUnique = f.isUniqueDomain( args );
                                if ( !isUnique ){
                                    self.setFormTarget( args.form, 'siteURL', null, {
                                        value: true,
                                        msg: 'Domain is already in use.'
                                    });
                                }
                                else{
                                    self.setFormTarget( args.form, 'siteURL', null, {
                                        value: false,
                                        msg: ''
                                    });
                                }
                                return newDomain;
                            }
                        },
                    }
                },
            },
        },
        siteURL: {
            "*": {
                newSiteData: {
                    items: {
                        siteURL: ( args ) => {
                            if ( args.hasOwnProperty( 'value' ) && args.value ){
                                if ( !args.value.includes( vars.suffix ) ){
                                    args.value = (`${ args.value }${ vars.suffix }`);
                                }
                                args.value = args.value.replace(/(\.(.*)\.com).*$/g, vars.suffix ).replace(' ', '');
                                if ( args.value === vars.suffix ) args.value = '';
                                let isUnique = f.isUniqueDomain( args );
                                if ( !isUnique ){
                                    self.setFormTarget( args.form, 'siteURL', null, {
                                        value: true,
                                        msg: 'Domain is already in use.'
                                    });
                                }
                                else{
                                    self.setFormTarget( args.form, 'siteURL', null, {
                                        value: false,
                                        msg: ''
                                    });
                                }
                            }
                        }
                    }
                }
            },
        },
        fdi_oem_program: {
            "FDINissanProgram": {
                themeSettings: {
                    items: {
                        color_primary: "#c3002f",
                        color_secondary: "#000000",
                        color_navbar_bg: "#c3002f",
                        color_secondary_navbar_bg: "#c3002f",
                        font_primary: "Nissan"
                    }
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#c3002f",
                        frontpage_contact_us_bg_color_secondary: "#c3002f"
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-nissan"
                    }
                }
            },
            "FDIInfinitiProgram": {
                themeSettings: {
                    items: {
                        color_primary: "#000000",
                        color_secondary: "#959595",
                        color_navbar_bg: "#000000",
                        color_secondary_navbar_bg: "#959595",
                        font_primary: "Infiniti"
                    }
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#000000",
                        frontpage_contact_us_bg_color_secondary: "#000000"
                    }
                },
                incentivesAndSpecials: {
                    items: {
                        carbox_specials_ribbon_location: "ribbon__top-above",
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-infiniti,shift"
                    }
                }
            },
            "FDIToyotaProgram": {
                themeSettings: {
                    items: {
                        color_primary: "#eb0a1e",
                        color_secondary: "#0a0a0a",
                        color_navbar_bg: "#0a0a0a",
                        color_secondary_navbar_bg: "#eb0a1e",
                        font_primary: "Toyota_Type"
                    }
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#eb0a1e",
                        frontpage_contact_us_bg_color_secondary: "#eb0a1e"
                    }
                }, 
                menus: {
                    items: {
                        "footer-nav-right": "FooterRight_Toyota",
                        'main-nav': "mainNav_Toyota"
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-toyota,shift"
                    }
                },
                windowSticker: {
                    items: {
                        show_window_sticker: "after_carousel_thumbs"
                    } 
                }
            },
            "Lexus": {
                themeSettings: {
                    items: {
                        color_primary: "#000000",
                        color_secondary: "#959595",
                        color_navbar_bg: "#000000",
                        color_secondary_navbar_bg: "#000000",
                        font_primary: "none"
                    }
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#000000",
                        frontpage_contact_us_bg_color_secondary: "#000000"
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-lexus"
                    }
                }
            },
            "Honda": {
                themeSettings: {
                    items: {
                        color_primary: "#007cc0",
                        color_secondary: "#f5f5f5",
                        color_navbar_bg: "#007cc0",
                        color_secondary_navbar_bg: "#f5f5f5",
                        font_primary: "none",
                        search__restrict_by_type: true,
                        hide_starting_at_main_nav: true,
                        hide_starting_at_new_vehicles: true
                    }
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#007cc0",
                        frontpage_contact_us_bg_color_secondary: "#007cc0"
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-honda"
                    }
                }
            },
            "Acura": {
                themeSettings: {
                    items: {
                        color_primary: "#e82c2a",
                        color_secondary: "#000000",
                        color_navbar_bg: "#000000",
                        color_secondary_navbar_bg: "#e82c2a",
                        font_primary: "none",
                        search__restrict_by_type: true,
                        hide_starting_at_main_nav: true,
                        hide_starting_at_new_vehicles: true
                    }
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#000000",
                        frontpage_contact_us_bg_color_secondary: "#000000"
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-acura"
                    }
                }
            },
            "GMCC": {
                backend: {
                    items: {
                        customSiteTags: "te-gmca,shift"
                    }
                }
            },
            "FDIHarleyProgram": {
                themeSettings: {
                    items: {
                        color_primary: "#fa6600",
                        color_secondary: "#000000",
                        color_navbar_bg: "#fa6600",
                        color_secondary_navbar_bg: "#000000",
                        font_primary: "Franklin Gothic ATF"
                    },
                },
                homepageContactMap: {
                    items: {
                        frontpage_contact_us_text_color: "ffffff",
                        frontpage_contact_us_bg_color_primary: "#fa6600",
                        frontpage_contact_us_bg_color_secondary: "#fa6600"
                    }
                },
                backend: {
                    items: {
                        customSiteTags: "te-harley,shift"
                    }
                }
            }
        }
    });

    const methods = ( self, data ) => ({
        isFunction: ( check ) => {
            if ( !check ) return false;
            let toString = {}.toString.call( check );
            return toString === '[object Function]' || toString === '[object AsyncFunction]';
        },
        setForm: ( form, sectionKey, index, value = null, error = null ) => {
            let edit = {
                ...form[sectionKey].items[index].config,
            };
            if ( value !== null ){
                edit.value = value;
            }
            if ( error ){
                edit.error = error;
            }

            form[sectionKey].items[index].config = edit;
        },
        setFormTarget: ( form, target, value = null, error = null ) => {
            let pos = formDataIndexMap[target] || null;
            if ( pos ){
                self.setForm( form, pos.sectionKey, pos.index, value, error );
            }
        },
        update: async ( event, sectionKey, index, form ) => {


            let target = event.target.name;
            let type = event.target.type;
            let value = type === 'checkbox' ? event.target.checked : event.target.value;
            if ( type !== 'file' ){
                self.setForm( form, sectionKey, index, value );
                self.form = form;
            }
            let items = {};


            if ( data && data.hasOwnProperty( target ) ) {
                console.log('data? val', target, data[target])
                if ( data[target].hasOwnProperty( value ) ) {
                    items = data[target][value];
                }
                if ( data[target].hasOwnProperty( '*' ) ) {
                    items = {
                        ...items,
                        ...data[target]['*'],
                    };
                }
            }
            for ( const [section, val] of Object.entries( items ) ) {
                let innerItems = val.items || {}; 

                for ( const [ id, val ] of Object.entries( innerItems ) ) {
                    if ( self.isFunction( val ) ){
                        let args = {
                            value,
                            existingDomains,
                            form,
                            sectionKey,
                            index,
                            event
                        };
                        let result = await val( args );
                        if ( result !== undefined ) {
                            self.setFormTarget( form, id, result );
                        }
                    }
                    else{
                        self.setFormTarget( form, id, val );
                    }
                }
            }
        },
    });

    return Object.assign( self, methods( self, data( self ) ) );
}

export default updateForm
